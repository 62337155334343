import autoBind from 'auto-bind';

class Modal {
    constructor(element, opts={}) {
        let {
            block = 'modal',
            classes = {},
            unloadAttr = [ 'src', 'srcset' ],
            id,
        } = opts;
        this.clicker = element;
        this.block = document.getElementById(id);
        this.id = id || element.id;
        this.classes = {
            block: block,
            ...classes
        };
        this.unloadAttr = unloadAttr;

        this.buttons = this.block.querySelectorAll('[data-modal-button]'); // add outer buttons too? make clicker an outer button?
        this.container = this.block.querySelector('[data-modal-content]');
        this.lazyContent = this.container.querySelectorAll('[src],[srcset]');

        this.block.classList.remove(...this.classes.remove, ...this.classes.open);
        this.usePopState = false;
        this.isOpen = false;

        this.openEvent = new CustomEvent('modalopen', {
            detail: { id: this.id },
            bubbles: true
        });
        this.closeEvent = new CustomEvent('modalclose', {
            detail: { id: this.id },
            bubbles: true
        });

        autoBind(this);
        this.addListeners();
        this.unloadContent();
    }

    unloadContent() {
        this.lazyContent.forEach((e, i) => {
            this.unloadAttr.forEach(attr => {
                if (e.hasAttribute(attr)) {
                    e.dataset[attr] = e[attr];
                    e.removeAttribute(attr);
                }
            });
            if (e.tagName === 'IFRAME') {
                let placeholder = document.createElement('div');
                placeholder.dataset.iframeReplace = i;
                e.replaceWith(placeholder);
            }
        });
        this.isLoaded = false;
    }

    loadContent() {
        if (this.isLoaded)
            return null;
        this.lazyContent.forEach((e, i) => {
            this.unloadAttr.forEach(attr => {
                if (e.dataset[attr])
                    e.setAttribute(attr, e.dataset[attr]);
            });
            if (e.tagName === 'IFRAME')
                this.container.querySelector(`div[data-iframe-replace="${i}"]`)
                    .replaceWith(e);
        });
        this.isLoaded = true;
    }

    open(pushState=this.usePopState) {
        if (pushState)
            window.history.pushState({ openModal: this.id }, document.title, '#' + this.id);

        this.loadContent();
        document.scrollingElement.style.width = document.scrollingElement.clientWidth + 'px';
        document.scrollingElement.style.overflow = 'hidden';
        this.block.classList.add(...this.classes.open);
        this.block.dispatchEvent(this.openEvent);
        this.isOpen = true;
    }

    close(pushState=this.usePopState) {
        // TODO: should tell youtube to pause when closing
        if (pushState) {
            if (history.state && history.state.openModal) {
                // to avoid polluting the user's history, closing should be equivalent to triggering a back button in most cases
                // the popstate listener will catch this and call the method again with pushState=false, to trigger the styling
                window.history.back();
                return null;
            }
            // only add history if page was opened to a url with a hash, without history of the base page to go back to
            window.history.pushState({ openModal: null }, document.title, window.location.pathname);
        }

        document.scrollingElement.style.width = null;
        document.scrollingElement.style.overflow = null;
        this.block.classList.remove(...this.classes.open);
        this.block.dispatchEvent(this.closeEvent);
        this.isOpen = false;
    }

    addListeners() {
        this.clicker.addEventListener('click', event => {
            event.preventDefault();
            this.open();
        });

        for (const button of this.buttons) {
            let action = button.dataset.modalButton;
            if (action && typeof this[action] === 'function') {
                button.addEventListener('click', event => {
                    event.preventDefault();
                    this[action]();
                });
            }
        }

        window.addEventListener('keydown', event => {
            // pressing escape key when modal is open
            // iframes steal focus in fullscreen so should not need to check for that
            if (this.isOpen && event.code === 'Escape')
                this.close();
        }, { passive: true });
    }
}

class ModalPage {
    constructor(modals) {
        this.modals = {};
        for (const modal of modals) {
            modal.usePopState = true;
            this.modals[modal.id] = modal;
        }

        this.scrollingElement = document.scrollingElement;

        const hash = window.location.hash;

        autoBind(this);
        this.addListeners();
        if (hash)
            this.open(hash.slice(1), false);
    }

    open(id, usePopState) {
        if (id === this.openModal)
            return null;
        const alreadyOpen = this.modals[this.openModal];
        const opening = this.modals[id];
        if (alreadyOpen)
            alreadyOpen.close(usePopState);
        if (opening)
            opening.open(usePopState);
    }

    addListeners() {
        window.addEventListener('popstate', event => {
            let target, hash = window.location.hash;
            if (event.state)
                target = event.state.openModal;
            else if (hash)
                target = hash.slice(1);
            this.open(target, false);
        });

        Object.values(this.modals).forEach(m => {
            m.block.addEventListener('modalopen', event => {
                this.openModal = event.detail.id;
            });
            m.block.addEventListener('modalclose', event => {
                if (this.openModal === event.detail.id)
                    this.openModal = false;
            });
        });
    }
}

export { Modal, ModalPage };
