import autoBind from 'auto-bind';

const getTransitionTime = (element, ...properties) => {
    const durSet = [0];
    const props = properties.length ? properties : [ 'all' ];
    const computedStyle = window.getComputedStyle(element);
    const prefix = computedStyle.getPropertyValue('transition-duration') ? '' : '-webkit-';
    const tProperty = computedStyle.getPropertyValue(prefix + 'transition-property').split(', ');
    const tDur = computedStyle.getPropertyValue(prefix + 'transition-duration').split(', ');
    const tDelay = computedStyle.getPropertyValue(prefix + 'transition-delay').split(', ');
    props.forEach(specified => {
        for (let i = 0; i < tProperty.length; i++) {
            if (specified === tProperty[i] || tProperty[i] === 'all' || specified === 'all') {
                const dur = Number((tDur[i] || tDur[0]).replace('s', ''));
                const delay = Number((tDelay[i] || tDelay[0]).replace('s', ''));
                durSet.push((dur + delay) * 1000);
            }
        }
    });
    return Math.max(...durSet);
};

class Carousel {
    constructor(element, opts={}) {
        let {
            mode = 'focus',
            classes = {},
            clickDelay,
            ...selectors
        } = opts;

        this.body = element;
        this.sel = { base: selectors.base || '.carousel' };
        this.sel = {
            ...this.sel,
            image: this.sel.base + '__image',
            button: this.sel.base + '__button',
            ...selectors
        };
        this.sel = {
            ...this.sel,
            imageFocus: this.sel.image + '--focus',
            imageCenter: this.sel.image + '--center',
            imageLeft: this.sel.image + '--left',
            imageRight: this.sel.image + '--right',
            buttonPrev: this.sel.button + '--left',
            buttonNext: this.sel.button + '--right',
            ...selectors
        };

        this.class = {
            imageFocus: this.sel.image.replace(/^\./, '') + '--focus',
            imageCenter: this.sel.image.replace(/^\./, '') + '--center',
            imageLeft: this.sel.image.replace(/^\./, '') + '--left',
            imageRight: this.sel.image.replace(/^\./, '') + '--right',
            ...classes
        };

        this.images = this.body.querySelectorAll(this.sel.image);
        this.buttons = this.body.querySelectorAll(this.sel.button);

        this.clickDelay = clickDelay || getTransitionTime(this.images[0]);
        this.defaultMethod = this[mode];
        this.enabled = true;

        autoBind(this);

        if (this.images.length > 1) {
            this.resetPositions();
            this.addListeners();
            this.defaultMethod(0);
        } else {
            for (let button of this.buttons)
                button.remove();
        }
    }

    resetPositions() {
        const classes = Object.values(this.class);
        for (const image of this.images) {
            image.classList.remove(...classes);
        }
    }

    setPosition(img, posName) {
        let classes = { ...this.class };
        delete classes[posName];
        classes = Object.values(classes);
        img.classList.add(this.class[posName]);
        img.classList.remove(...classes);
    }

    index(i) {
        const len = this.images.length;
        if (i < 0)
            return len - 1;
        else if (i >= len)
            return 0;
        return i;
    }

    center(index) {
        const len = this.images.length;
        const i = this.index(index);

        for (let j = 0; j < len; j++) {
            let img = this.images[j];
            if (j === i)
                this.setPosition(img, 'imageCenter');
            else if (i === 0 && j === len - 1)
                this.setPosition(img, 'imageLeft');
            else if (i === len - 1 && j === 0)
                this.setPosition(img, 'imageRight');
            else if (j < i)
                this.setPosition(img, 'imageLeft');
            else if (j > i)
                this.setPosition(img, 'imageRight');
        }
        this.focused = i;
    }

    focus(index) {
        const i = this.index(index);
        this.images[this.focus].classList.remove(this.class.imageFocus);
        this.images[i].classList.add(this.class.imageFocus);
        this.focused = i;
    }

    prev() {
        this.defaultMethod(this.focused - 1);
    }

    next() {
        this.defaultMethod(this.focused + 1);
    }

    disable() {
        this.enabled = false;
        window.setTimeout(() => this.enabled = true, this.clickDelay);
    }

    addListeners() {
        for (const button of this.buttons) {
            if (button.matches(this.sel.buttonPrev)) {
                button.addEventListener('click', e => {
                    e.preventDefault();
                    if (this.enabled) {
                        this.prev();
                        this.disable();
                    }
                });
            } else if (button.matches(this.sel.buttonNext)) {
                button.addEventListener('click', e => {
                    e.preventDefault();
                    if (this.enabled) {
                        this.next();
                        this.disable();
                    }
                });
            }
        }
    }
}

export default Carousel;
