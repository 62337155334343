import autoBind from 'auto-bind';

function paramMatch(targets, filterParams) {
    if (!filterParams)
        return true;
    for (const p of filterParams) {
        if ([].concat(targets).includes(p))
            return true;
    }
    return false;
}

function getSearchParams(key) {
    let params = new URLSearchParams(window.location.search);
    params = params.getAll(key);
    return params.length ? params : null;
}

class FilterButton {
    constructor(element, opts={}) {
        let {
            filterKey = 'filter',
            appliedClass = []
        } = opts;
        this.element = element;

        const url = new URL(element.href);
        this.params = url.searchParams;
        this.targets = this.params.getAll(filterKey);
        this.filterKey = filterKey;
        this.applied = [].concat(appliedClass);
        this.filtered = false;

        // dispatch custom event to be caught by document;
        this.filterEvent = new CustomEvent('filterapplied', {
            detail: this.targets,
            bubbles: true
        });
        this.unfilterEvent = new CustomEvent('filterapplied', {
            detail: null,
            bubbles: true
        });

        autoBind(this);
        let initial = getSearchParams(this.filterKey);
        if (initial && paramMatch(this.targets, initial)) {
            this.addStyles();
            this.filtered = true;
        }
        this.addListeners();
    }

    updateQueryString(targets=this.targets) {
        const params = this.params;
        params.set(this.filterKey, targets === null ? '' : targets);
        let newUrl = new URL(window.location);
        newUrl.hash = '';
        for (let [key, val] of params.entries()) {
            if (val) newUrl.searchParams.set(key, val);
            else newUrl.searchParams.delete(key);
        }

        let newState = { filterTargets: targets };
        if (window.history.state)
            newState = { ...newState, ...window.history.state };
        window.history.replaceState(newState, params.toString(), newUrl.toString());
    }

    addStyles() {
        if (this.applied.length)
            this.element.classList.add(...this.applied);
    }

    removeStyles() {
        if (this.applied.length)
            this.element.classList.remove(...this.applied);
    }

    filter() {
        this.addStyles();
        this.updateQueryString();
        this.filtered = true;
        this.element.dispatchEvent(this.filterEvent);
    }

    unfilter() {
        this.removeStyles();
        this.updateQueryString(null);
        this.filtered = false;
        this.element.dispatchEvent(this.unfilterEvent);
    }

    addListeners() {
        this.element.addEventListener('click', event => {
            event.preventDefault();
            if (this.filtered)
                this.unfilter();
            else
                this.filter();
        });

        document.addEventListener('filterapplied', event => {
            if (!paramMatch(this.targets, event.detail)) {
                this.removeStyles();
                this.filtered = false;
            }
        });
    }
}

class FilterItem {
    constructor(element, opts={}) {
        let {
            filterBy = [],
            filterKey = 'filter'
        } = opts;
        this.element = element;
        this.filterData = filterBy;
        this.filterKey = filterKey;
        this.parent = element.parentNode;
        this.hidden = false;

        autoBind(this);
        this.filter();
        this.addListeners();
    }

    hide() {
        if (this.hidden)
            return null;
        this.parent.removeChild(this.element);
        this.hidden = true;
    }

    show() {
        this.parent.appendChild(this.element);
        this.hidden = false;
    }

    filter(targets=getSearchParams(this.filterKey)) {
        if (paramMatch(this.filterData, targets))
            this.show();
        else
            this.hide();
    }

    addListeners() {
        document.addEventListener('filterapplied', event =>
            this.filter(event.detail), { passive: true });
    }
}

export { FilterButton, FilterItem };
