/*
 * IE11 Polyfills
 *
 * Object Fit:          npm.im/object-fit-images
 * Scroll Restoration:  npm.im/scroll-restoration-polyfill
 * Details Element:     npm.im/details-element-polyfill
 * URL Search Params:   npm.im/@ungap/url-search-params
 *
 * Libraries
 * 
 * Zenscroll:           npm.im/zenscroll
 */

import makeHeader from '@dawaltconley/header-basic/browser';
import { FilterButton, FilterItem } from './filter-button.mjs';
import { Modal, ModalPage } from './modal.mjs';
import Carousel from './carousel.mjs';

const cloneAfter = document.querySelectorAll('[data-clone-after]');

for (const element of cloneAfter) {
    let clone, target = element;
    if (element.dataset.cloneAfter)
        target = document.querySelector(element.dataset.cloneAfter);
    if (element.nodeName !== 'TEMPLATE')
        clone = element.cloneNode(true);
    else if (element.content) // if element is <template> and that's supported
        clone = element.content.cloneNode(true);
    target.after(clone);
}

/*
 * Header
 */

const headerElement = document.getElementById('header');
let fixedHeader;

if (headerElement)
    ({ fixed:fixedHeader } = makeHeader(headerElement));

/*
 * Film Page
 */

const filterButtons = Array.from(document.querySelectorAll('[data-filter-button]'))
    .map(e => new FilterButton(e, JSON.parse(e.dataset.filterButton)));

const filterItems = Array.from(document.querySelectorAll('[data-filter-item]'))
    .map(e => new FilterItem(e, JSON.parse(e.dataset.filterItem)));

const carousels = Array.from(document.querySelectorAll('.carousel'))
    .map(e => new Carousel(e, { mode: 'center' }));

const modals = Array.from(document.querySelectorAll('[data-modal]'))
    .map(e => new Modal(e, JSON.parse(e.dataset.modal)));

let modalPage;
if (modals.length)
    modalPage = new ModalPage(modals);

if (fixedHeader && modalPage) {
    document.addEventListener('modalopen', () => {
        const { menu } = fixedHeader;
        fixedHeader.disableScroll();
        fixedHeader.slideUp();
        if (menu && menu.state === 'open') {
            menu.close();
        }
    });
    document.addEventListener('modalclose', () => fixedHeader.enableScroll());
}

export { fixedHeader, filterButtons, filterItems, modals, modalPage, carousels };
